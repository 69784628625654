@import "./animations.scss";

@mixin pageStyles {
  position: fixed;
  inset: 0;
  @include box(100%, 100%);
  @include verticalFlex();
}

@mixin whiteBox() {
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(16, 28, 36, 0.078);
  border-radius: 5px;
  overflow: hidden;
  @include verticalFlex();
  animation: pageTransition 0.4s ease-in-out forwards;
  transform-origin: bottom;
}

@mixin absoluteCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
}
@mixin preventSelection() {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

@mixin fontProperties($size, $weight, $color) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin chartScroll() {
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 7px !important;

    border: none !important;
    outline-width: 0 !important;
    border-style: none !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #b8c4cd;
    border-radius: 20px;
    cursor: pointer;
    transition: all 150ms ease-in-out;

    &:hover {
      background: #007ef3;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    padding: 2px;
    background: #f2f5fa;
    background-color: #f2f5fa;
    border: none !important;
    outline-width: 0 !important;
    border-style: none !important;
  }
}

@mixin setToCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hideScrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@mixin addLineClamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin setSpaceBetween() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flexAlign() {
  display: flex;
  align-items: center;
}

@mixin setFlexEnd() {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@mixin setFlexStart() {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@mixin verticalFlex() {
  display: flex;
  flex-direction: column;
}

@mixin flexAlign() {
  display: flex;
  align-items: center;
}

@mixin setTextInOneLine() {
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

@mixin removeDefaultStyle() {
  border-style: none;
  background: none;
  background-color: none;
  outline-width: 0;
  outline-style: none;
}

@mixin skeletonAnimate() {
  background: linear-gradient(110deg, #ecf1f6 8%, #f9f9f9 18%, #ecf1f6 33%);
  background-color: #ecf1f6;
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s skeletonAnimation linear infinite;
}

@mixin responsiveGrid($elementWidth, $rowGap: 10px, $columnGap: 10px) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($elementWidth), 1fr);
  row-gap: $rowGap;
  column-gap: $columnGap;
}

@mixin grid($columns, $rowGap: 10px, $columnGap: 10px) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  row-gap: $rowGap;
  column-gap: $columnGap;
}

@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

//// flex toolkit
@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexCenterColumn {
  @include flexCenter;
  flex-direction: column;
}

@mixin flexAlign {
  display: flex;
  align-items: center;
}

@mixin animate($delay) {
  opacity: 0;

  @-webkit-keyframes animateLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateY(100px); /* Safari needs this */
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0); /* Safari needs this */
      transform: translateY(0);
    }
  }

  @keyframes animateLeft {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  // animation: animateLeft 0.5s $delay ease-out forwards;
  @include animation(animateLeft, 0.5s, ease-out, $delay, 1, normal, forwards);
}

@mixin animation(
  $name,
  $duration,
  $timing-function: ease,
  $delay: 0s,
  $iteration-count: 1,
  $direction: normal,
  $fill-mode: none
) {
  // @-webkit-keyframes $name; /* For Safari and older browsers */
  // @-moz-keyframes $name; /* For older versions of Firefox */
  // @-o-keyframes $name; /* For older Opera browsers */

  -webkit-animation-name: $name;
  -moz-animation-name: $name;
  -o-animation-name: $name;
  animation-name: $name;

  -webkit-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -o-animation-duration: $duration;
  animation-duration: $duration;

  -webkit-animation-timing-function: $timing-function;
  -moz-animation-timing-function: $timing-function;
  -o-animation-timing-function: $timing-function;
  animation-timing-function: $timing-function;

  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;

  -webkit-animation-iteration-count: $iteration-count;
  -moz-animation-iteration-count: $iteration-count;
  -o-animation-iteration-count: $iteration-count;
  animation-iteration-count: $iteration-count;

  -webkit-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -o-animation-direction: $direction;
  animation-direction: $direction;

  -webkit-animation-fill-mode: $fill-mode;
  -moz-animation-fill-mode: $fill-mode;
  -o-animation-fill-mode: $fill-mode;
  animation-fill-mode: $fill-mode;

  @media (prefers-reduced-motion: reduce) {
    -webkit-animation: none;
    -moz-animation: none;
    -o-animation: none;
    animation: none;
  }
}

$breakpoints: (
  "mobile": 576px,
  "tab": 768px,
  "tab2": 992px,
  "laptop": 1200px,
  "desktop": 1200px,
);

// Responsive media query mixin
@mixin respondTo($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $size: map-get($breakpoints, $breakpoint);
    @media (min-width: #{$size}) {
      @content;
    }
  } @else {
    @warn "The breakpoint `#{$breakpoint}` is not defined in the map.";
  }
}

@mixin section($maxWidth: 1200px) {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
}

@mixin mobileView {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin desktopPaddings {
  padding: 0 50px;
}

@mixin mobilePaddings {
  padding: 0 20px;
}
