@import "../styles/mixins.scss";

.contry-marker-box {
  border-radius: 5px;
  background: rgba(253, 253, 253, 0.1);
  backdrop-filter: blur(20px);
  padding: 10px;
  @include preventSelection();

  .cmb-hovered-content {
    display: none;
    .cmb-images {
      display: flex;
      gap: 10px;
      .cmb-img {
        width: 100px;
        aspect-ratio: 19/11;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  
    .cmb-title {
      @include fontProperties(14px, 500, #000);
      margin-top: 10px;
    }
    .cmb-name {
      @include fontProperties(13px, 500, #000);
      margin-top: 10px;
    }
  
    .cmb-desc {
      @include fontProperties(11px, 400, #6F757B);
      margin-top: 2px;
    }
  }

  .cmb-initial-content {
    img {
      height: 18px;
      vertical-align: middle;
    }

    .cmbi-name {
      @include fontProperties(12px, normal, #000);
      @include setTextInOneLine();
      margin-top: 5px;
    }
  }

  &:hover {
    .cmb-initial-content {
      display: none;
    }

    .cmb-hovered-content {
      display: block;
    }
  }
}
