@import "../../styles/mixins.scss";

@mixin boldText {
  font-family: "Nunito Sans", serif;
}

@mixin serifText {
  font-family: "Kantumruy Pro", serif;
  font-weight: 200;
}

.fallback-loader {
  position: fixed;
  inset: 0;
  z-index: 998;
  background-color: #000;
  @include setToCenter();
  flex-direction: column;
}

.section-title {
  font-size: 140px;
  font-weight: 800;
  color: transparent;
  @include boldText();

  transition: all 0.4s ease;
  position: relative;
  @include flexAlign();

  .section-title-content {
    display: inline-block;
    position: relative;
    -webkit-text-stroke: 2px #fff;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: calc(100% - 10px);
      width: 0%;
      height: 2px;
      background-color: #fff;
      transition: all 0.4s ease;
    }

    &:hover {
      color: #fff;
      &::after {
        width: 100%;
      }
    }

    &.dark {
      -webkit-text-stroke: 2px #000;

      &:hover {
        color: #000;
      }

      &::after {
        background-color: #000;
      }
    }
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }

  @include mobileView() {
    font-size: 40px;
  }
}

.home3 {
  min-height: 300vh;
  max-width: 100vw;
  // overflow-x: hidden;
  // background-color: #000;

  .s1 {
    height: 100vh;
    @include setToCenter();
    align-items: flex-end;
    flex-direction: column;
    @include desktopPaddings();

    .s1-heading {
      font-size: 100px;
      font-weight: 800;
      color: #fff;
      @include boldText();

      span {
        display: inline-block;
        // @include boldText();
        // color: transparent;
        // -webkit-text-stroke: 1px #fff;
      }
    }

    .s1-subheading {
      font-size: 40px;
      font-weight: 200;
      color: #fff;
      @include serifText();

      span {
        display: inline-block;
        @include serifText();
      }
    }

    .s1-content {
      font-size: 22px;
      color: #bbb;
      font-weight: 200;
      @include serifText();
      text-align: right;
      max-width: 50%;
      margin-top: 30px;

      div {
        font-weight: 200;
        @include serifText();
      }

      span {
        display: inline-block;
        @include serifText();
        font-weight: 400;
        color: #fff;
      }
    }

    @include mobileView() {
      align-items: center;
      height: unset;
      @include mobilePaddings();
      padding-top: 450px;
      padding-bottom: 100px;

      .s1-heading {
        font-size: 40px;
        text-align: center;
      }

      .s1-subheading {
        font-size: 25px;
      }

      .s1-content {
        font-size: 16px;
        max-width: unset;
        text-align: center;
        margin-top: 15px;
        padding: 0 10px;
      }
    }
  }

  #page-navigations {
    // padding-top: 100px;
  }

  .navigations {
    @include desktopPaddings();
    margin: 100px 0;
    // margin-bottom: 100px;
    display: flex;
    gap: 20px;
    @include setSpaceBetween();
    .navigation {
      @include fontProperties(20px, 400, #aaa);
      text-decoration: none;
      display: inline-block;
      position: relative;

      .nav-index {
        position: absolute;
        left: -5px;
        bottom: -21px;
        font-size: 70px;
        font-weight: 800;
        color: transparent;
        @include boldText();
        opacity: 0.1;
        -webkit-text-stroke: 1px yellow;
        transition: all 0.4s ease;
      }
      .nav-title {
        font-weight: 200;
        color: #aaa;
        @include serifText();
        margin: 0 5px;
        transition: all 0.4s ease;
      }

      &:hover {
        .nav-index {
          -webkit-text-stroke: 1px var(--highlight-color);
          opacity: 0.8;
          transform: translate(10px, -25px);
        }
        .nav-title {
          color: #fff;
          font-weight: 400;
        }
      }
    }

    @include mobileView() {
      @include mobilePaddings();
      flex-wrap: wrap;
      justify-content: center;

      .navigation {
        font-size: 14px;

        .nav-index {
          font-size: 40px;
        }
      }
    }
  }

  .scroll-down {
    @include setToCenter();
    flex-direction: column;
    height: 200px;
    margin-bottom: 100px;

    .sd-content {
      @include serifText();
      @include fontProperties(20px, 300, #fff);
    }
    .sd-description {
      @include serifText();
      @include fontProperties(16px, 300, rgba(255, 255, 255, 0.4));
    }
    .sd-mouse {
      @include box(30px, 45px);
      border-radius: 20px;
      border: 1px solid #aaa;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 10px;

      @keyframes scrollDown {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(50%);
        }
      }

      .sdm-scrollbar {
        @include box(5px, 10px);
        background-color: #ddd;
        border-radius: 5px;
        margin-top: 7px;
        animation: scrollDown 1s linear infinite;
      }
    }
  }

  .s2 {
    .s2-title {
      margin-left: 50px;
    }
    .s2-body {
      margin-top: 30px;
      max-width: 100vw;
      overflow: hidden;
    }

    @include mobileView() {
      .s2-title {
        margin-left: 20px;
      }
    }
  }

  .s3 {
    @include desktopPaddings();
    margin-top: 250px;
    padding-bottom: 200px;

    .s3-body {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .s3-process {
        @include fontProperties(80px, 700, #fff);

        span {
          // display: inline-block;
          opacity: 0.2;
        }
      }
    }

    @include mobileView() {
      @include mobilePaddings();
      padding-bottom: 50px;
      margin-top: 100px;

      .s3-body {
        margin-top: 20px;
        gap: 20px;

        .s3-process {
          font-size: 40px;
        }
      }
    }
  }

  .s4 {
    @include desktopPaddings();
    position: relative;
    padding-bottom: 100px;
    margin-top: 500px;
    // padding-top: 300px;

    .s4-round-border {
      height: 100px;
      border-bottom-left-radius: 500%;
      border-bottom-right-radius: 500%;
      border-bottom: 2px dashed #fff;
    }
    .s4-body {
      max-width: 1300px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      position: relative;
      // overflow: hidden;
      min-height: 70vh;

      // &::after {
      //   content: "";
      //   position: absolute;
      //   width: 80%;
      //   aspect-ratio: 1/1;
      //   border-radius: 50%;
      //   border: 2px dashed rgba(255, 255, 255, 0.3);
      //   left: 50%;
      //   top: -170%;
      //   transform: translateX(-50%);
      // }
    }
  }

  .sec-5 {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 200px;
    width: 100vw;
    overflow-x: hidden;

    .s5-slider-container {
      $sliderWidth: calc(-8425px + 100vw);
      $sliderWidthRev: calc(8425px - 100vw);
      $sliderSpeed: 80s;

      @include mobileView() {
        $sliderWidth: calc(-3205px + 100vw);
        $sliderWidthRev: calc(3205px - 100vw);
        $sliderSpeed: 80s;
      }

      .s5-slider {
        margin-top: 80px;
        padding: 10px 0;
        @include flexAlign();
        gap: 150px;
        animation: infiniteLoop $sliderSpeed linear infinite;
        @include preventSelection();

        @keyframes infiniteLoop {
          to {
            transform: translateX(calc($sliderWidth));
          }
        }

        @keyframes infiniteLoopReverse {
          to {
            transform: translateX(calc($sliderWidthRev));
          }
        }

        &.reverse {
          display: flex;
          flex-direction: row-reverse;
          animation: infiniteLoopReverse $sliderSpeed linear infinite;
        }
      }

      @include mobileView() {
        .s5-slider {
          gap: 50px;
        }
      }
    }

    @include mobileView() {
      padding-top: 30px;
      padding-bottom: 80px;
    }
  }

  .team-card {
    flex-shrink: 0;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: calc(100% + 70px);
      top: 50%;
      transform: translateY(-50%);
      @include box(10px);
      background-color: #fff;
      border-radius: 50%;
    }

    .tc-name {
      @include fontProperties(120px, 500, #000);
      color: #d9d9d9;
      margin: 0;
    }

    .tc-role {
      @include fontProperties(30px, 400, #000);
      color: #fff;
      @include flexAlign();
      gap: 10px;
    }

    .linked-in-icon {
      @include box(30px);
    }

    &:nth-child(odd) {
      .tc-name {
        color: transparent;
        -webkit-text-stroke: 1px #fff;
      }
    }

    @include mobileView() {
      .tc-name {
        font-size: 40px;
      }

      .tc-role {
        font-size: 20px;
      }

      &::after {
        left: calc(100% + 25px);
        @include box(5px);
      }
    }
  }

  .s6 {
    position: relative;
    z-index: -2;
    video {
      width: 100vw;
      object-fit: cover;
    }
  }

  .s7 {
    @include desktopPaddings();
    padding-top: 100px;
    padding-bottom: 10px;

    @include mobileView() {
      @include mobilePaddings();
      padding-top: 80px;
      padding-bottom: 50px;
    }
  }

  .s8 {
    @include desktopPaddings();
    padding-top: 300px;
    padding-bottom: 100px;

    clip-path: polygon(25% 10%, 100% 0, 100% 100%, 0 100%, 0 10%);
    background-color: #fff;

    .s8-content {
      @include flexAlign();
      margin-top: 50px;
    }

    .s8-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
      .s8-title {
        @include fontProperties(80px, 700, #000);
        text-align: right;
      }
      .s8-description {
        @include fontProperties(30px, 400, #000);
        margin-top: 20px;
        text-align: right;
      }
      .s8-mail {
        display: inline-block;
        @include fontProperties(60px, 500, #000);
        text-decoration: underline;
        margin-top: 0px;
        background: linear-gradient(90deg, #b113ef 0%, #0082e6 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .s8-socials {
        @include flexAlign();
        gap: 70px;
        margin-top: 10px;
        .social-icons {
          fill: #000;
          @include box(50px);
        }
      }
    }

    .s8-canvas-elem {
      flex: 1;
      aspect-ratio: 1/0.7;
      position: relative;
      .s8-canvas {
        position: absolute !important;
        inset: -100px !important;
        bottom: -150px !important;
        top: -150px !important;
        height: calc(100% + 300px) !important;
        width: calc(100% + 200px) !important;
      }
    }

    @include mobileView() {
      @include mobilePaddings();
      padding-top: 100px;
      padding-bottom: 50px;

      .s8-content {
        flex-direction: column-reverse;
        gap: 20px;
      }

      .s8-right {
        align-items: center;
        .s8-title {
          font-size: 40px;
        }
        .s8-description {
          font-size: 16px;
        }

        .s8-mail {
          font-size: 20px;
          margin-top: 10px;
        }

        .s8-socials {
          gap: 20px;
        }
      }

      .s8-canvas-elem {
        width: 100%;
        .s8-canvas {
          position: relative !important;
          inset: 0 !important;
          bottom: 0 !important;
          top: 0 !important;
          height: 100% !important;
          width: 100% !important;
        }
      }
    }
  }

  .copy {
    @include desktopPaddings();
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000;
    background: linear-gradient(45deg, #b113ef 0%, #0082e6 100%);
    @include setSpaceBetween();
    .copy-content {
      @include fontProperties(15px, 200, #fff);
      span {
        @include serifText();
      }
    }

    .copy-navigations {
      @include flexAlign();
      gap: 20px;
      .cn-menu {
        @include fontProperties(15px, 200, #fff);
        @include serifText();
        transition: all 0.4s ease;
        cursor: pointer;

        &:hover {
          font-weight: 400;
        }
      }
    }

    @include mobileView() {
      @include mobilePaddings();
      flex-direction: column;
      padding-top: 10px;
      padding-bottom: 10px;
      gap: 5px;

      .copy-content {
        font-size: 11px;
      }
      .copy-navigations {
        gap: 10px;
        .cn-menu {
          font-size: 12px;
        }
      }
    }
  }
}

.service-row {
  .sr-slider {
    display: flex;
    gap: 10px;

    .service-row-heading {
      @include flexAlign();
      gap: 10px;

      &.left {
        flex-shrink: 0;
        max-width: 100vw;
        width: 100vw;
        overflow: hidden;
        flex-direction: row-reverse;
      }

      .service-row-content {
        display: inline-block;
        font-size: 100px;
        font-weight: 900;
        color: rgba(255, 255, 255, 0.1);
        @include boldText();
        @include setTextInOneLine();
        flex-shrink: 0;

        &.active {
          color: #eee;
        }

        @include mobileView() {
          font-size: 40px;
        }
      }
    }
  }
}

.footer-wrapper-box {
  background-color: #fff;

  .fwb-box {
    @include desktopPaddings();
    padding-top: 70px;

    .f-title {
      @include fontProperties(20px, normal, #000);
    }

    .footer {
      display: flex;
      gap: 40px;
      padding-bottom: 100px;
      padding-top: 40px;
    }

    .footer-column {
      flex: 1;
      .fc-contry {
        @include fontProperties(60px, 700, #000);
        @include boldText();
      }

      .fc-company {
        @include boldText();
        @include fontProperties(25px, 600, #000);
        margin-top: 7px;
      }

      .fc-address {
        @include serifText();
        @include fontProperties(16px, 400, #989898);
        margin-top: 25px;

        span {
          display: inline-block;
        }
      }
      .fc-redirect {
        margin-top: 40px;
        @include fontProperties(18px, 500, #000);
        @include flexAlign();
        display: inline-flex;
        cursor: pointer;
        gap: 10px;

        .fc-arrow {
          fill: #000;
        }
      }
    }

    @include mobileView() {
      @include mobilePaddings();
      .footer {
        flex-direction: column;
        padding-bottom: 50px;
      }

      .footer-column {
        .fc-contry {
          font-size: 40px;
        }

        .fc-company {
          font-size: 16px;
          margin-top: 0px;
        }

        .fc-address {
          font-size: 12px;
          margin-top: 5px;
        }

        .fc-redirect {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
}

.process-card {
  position: absolute;
  @include setToCenter();
  flex-direction: column;
  .plane {
    @include flexAlign();
    display: inline-flex;
    // width: 80px;
    position: relative;
    z-index: 2;

    .plane-wing {
      clip-path: polygon(100% 0, 0 100%, 100% 80%);
      @include box(50px, 120px);

      &.right {
        clip-path: polygon(0 0, 100% 100%, 0 80%);
        opacity: 0.8;
      }
    }

    &.index-1 {
      rotate: 135deg;
      .plane-wing {
        background-color: rgba(255, 255, 255, 0.2);
        // background-color: red;
      }
    }

    &.index-2 {
      rotate: 112.5deg;
      .plane-wing {
        background-color: rgba(255, 255, 255, 0.4);
        // background-color: greenyellow;
      }
    }
    &.index-3 {
      rotate: 90deg;
      .plane-wing {
        background-color: rgba(255, 255, 255, 0.6);
        // background-color: purple;
      }
    }
    &.index-4 {
      rotate: 67.5deg;
      .plane-wing {
        background-color: rgba(255, 255, 255, 0.8);
        // background-color: pink;
      }
    }
    &.index-5 {
      rotate: 45deg;
      .plane-wing {
        background-color: rgba(255, 255, 255, 1);
        // background-color: skyblue;
      }
    }
  }
  .pc-content {
    @include fontProperties(22px, 400, #fff);
    @include serifText();
    text-align: center;
    margin-top: 20px;
    max-width: 150px;
  }

  .pc-count {
    @include fontProperties(100px, 900, #fff);
    @include boldText();
    margin-top: 20px;
    opacity: 0.05;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1;
    line-height: 1;
  }

  --card-width: -100px;
  &.index-1 {
    top: 0%;
    left: 0%;
  }
  &.index-2 {
    top: 25%;
    left: 20%;
  }
  &.index-3 {
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
  &.index-4 {
    top: 25%;
    right: 20%;
  }
  &.index-5 {
    top: 0;
    right: 0;
  }
}

.stars {
  position: fixed;
  inset: 0;
  z-index: -3;
  width: 100vw;
  height: 100vh;

  .stars-comet {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(-135deg);
    top: 10%;
    @include box(3px);
    background-color: #676768;
    border-radius: 50%;
    position: relative;
    filter: drop-shadow(0px 0px 2px rgba(255, 255, 255, 0.9));

    &::after {
      content: "";
      clip-path: unset;
      position: absolute;
      top: -0px;
      left: 50%;
      width: 2px; // Tail thickness
      height: 250px; // 6 times the height (6 * 10px)

      background: linear-gradient(
        to bottom,
        rgba(183, 113, 229, 0.9),
        rgba(16, 67, 159, 0.5),
        rgba(255, 255, 255, 0)
      );
      transform: translateX(-50%) rotate(0deg);
      border-radius: 50%;
      filter: blur(2px);
    }

    // Animation for meteor movement
    animation: meteor-move 1.8s linear infinite;

    &.comet-2 {
      top: -10%;
      left: 50%;
      animation-delay: 0.5s;
      scale: 0.8;
    }

    &.comet-3 {
      top: 10%;
      left: 100%;
      scale: 0.5;
    }

    &.comet-4 {
      top: -10%;
      left: 80%;
      animation-delay: 1s;
      scale: 1.3;
    }

    &.comet-5 {
      top: -10%;
      left: 30%;
      animation-delay: 2s;
    }
  }
}

@keyframes meteor-move {
  0% {
    translate: 0 0;
  }
  100% {
    translate: -150vh 150vh;
  }
}
