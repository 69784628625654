@import "../../styles/mixins.scss";

@mixin serifText {
  font-family: "Kantumruy Pro", serif;
  font-weight: 400;
}

@mixin boldText {
    font-family: "Nunito Sans", serif;
  }

.connect-us {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 500px;

  div {
    @include fontProperties(40px, 400, #fff);
    // text-align: right;
    line-height: 1;
    @include boldText();
  }

  button {
    margin-top: 50px;
    @include fontProperties(25px, 400, #fff);
    width: fit-content;
    @include serifText();
    padding: 20px 50px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: radial-gradient(circle, #0082e6, #b113ef, #0082e6);
    background-size: 300%;
    border: none;
    animation: gradient-move 10s linear infinite;

    &:hover {
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }

    @keyframes gradient-move {
      0% {
        background-position: 25% 25%;
      }
      50% {
        background-position: 50% 50%;
      }
      100% {
        background-position: 75% 75%;
      }
    }
  }

  @include mobileView() {
    div {
      @include fontProperties(20px, 400, #fff);
    }

    button {
      @include fontProperties(16px, 400, #fff);
      padding: 10px 20px;
    }
  }
}

.cu-input {
  background-color: transparent;
  border: none;
  padding: 10px;
  @include boldText();
  font-size: 40px;
  font-weight: 500;
  color: #fff;
  outline-width: 0;
  height: 50px;
  transition: all 0.3s ease;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
  display: inline-block;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }

  @include mobileView() {
    font-size: 20px;
    height: 30px;
  }
}

.cu-select {
  position: relative;
  cursor: pointer;

  .cus-input {
    @include flexAlign();
    gap: 10px;
    border-bottom: 1px solid #fff;
    padding: 5px 10px;
    // border-radius: 5px;
    // background-color: rgba(255, 255, 255, 0.1);

    .cus-content {
      flex: 1;
    //   max-width: 200px;
      @include boldText();
      font-size: 40px;
      color: rgba(255, 255, 255, 0.3);

      @include setTextInOneLine();
      @include addLineClamp(1);

      &.active {
        color: #fff;
      }
    }

    .cus-arrow {
      display: inline-block;
      @include setToCenter();
      @include box(20px, 20px);
      font-size: 40px;
      font-weight: 600;
      color: #fff;
      transition: all 0.3s ease;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .cus-options {
    position: absolute;
    top: 100%;
    left: 0;
    // width: 100%;
    top: calc(100% + 5px);
    background-color: #333;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    @include hideScrollbar();

    .cus-option {
      padding: 5px 10px;
      @include serifText();
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  @include mobileView() {
    .cus-input {
      .cus-content {
        font-size: 20px;
      }
      .cus-arrow {
        font-size: 20px;
      }
    }
  }
}
