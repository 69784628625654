* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "Maven Pro", sans-serif;
  font-family: "SF Pro Display", sans-serif;
  scroll-behavior: smooth;
  // remove click effect for mobile
  -webkit-tap-highlight-color: transparent; // Remove default mobile highlight
}

body {
  background-color: #000;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #000;

  .loading-screen-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @keyframes progress {
      0% {
        background-size: 0% 100%;
      }
      100% {
        background-size: 100% 100%;
      }
    }

    @mixin setTextInOneLine() {
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }

    .loading-screen-content-title {
      font-size: 80px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      display: inline-block;
      color: rgba(255, 255, 255, 0.1); // Light gray for unfilled text
      overflow: hidden;
      @include setTextInOneLine();

      .text-fill {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: #fff;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        white-space: nowrap;
        overflow: hidden;
        transition: width 0.3s linear;
      }
    }
  }

  .loading-screen-content-progress {
    font-size: 100px;
    font-weight: bold;
    text-align: center;
    // color: #fff;
    color: transparent;
    position: absolute;
    z-index: -2;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 250px), calc(-50% + 70px));
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
  }
}
