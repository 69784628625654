@import "../../styles/mixins.scss";

.navbar-comp {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 15;
  padding: 25px 20px 0 25px;
  @include setSpaceBetween();
  // background-color: rgba(0, 0, 0, 0.05);
  // backdrop-filter: blur(50px);
  transition: all 150ms ease-in;
  transform: translateY(-100%);

  &.active {
    transform: translateY(0);
  }

  .nc-logo {
    @include flexAlign();
    gap: 8px;
    position: relative;
    z-index: 3;

    .nc-image {
      vertical-align: middle;
      height: 23px;
      filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
    }
    .nc-line {
      @include box(0.5px, 30px);
      background-color: #fff;
      transition: all 0.1s ease-in;
    }
    .nc-content {
      @include fontProperties(11px, 400, #fff);
      line-height: 40px;
      transition: all 0.2s ease-in;
    }
  }
  .nc-ham {
    @include box(30px);
    @include setToCenter();
    flex-direction: column;
    gap: 6px;
    cursor: pointer;
    @include preventSelection();
    position: relative;
    z-index: 1;

    .spot-spreader {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #000;
      z-index: 0;
      border-radius: 50%;
      width: 0;
      height: 0;
      transition: all 0.7s ease-in-out;
    }

    $barWidth: 23px;
    $hoverRotationAngle: -20deg;

    .nch-bar {
      flex-shrink: 0;
      @include box($barWidth, 2px);
      border-radius: 2px;
      background-color: #ddd;
      transition: all 150ms ease-in-out;
      position: relative;
      z-index: 3;
    }

    &.active {
      .bar1 {
        transform-origin: left;
        transform: rotate(45deg);
        width: $barWidth;
      }
      .bar2 {
        transform-origin: center;
        scale: 0;
      }

      .bar3 {
        transform-origin: left;
        transform: rotate(-45deg);
        width: $barWidth;
      }

      .spot-spreader {
        @keyframes showSpreader {
          0% {
            @include box(0);
          }
          90% {
            @include box(5000px);
          }
          100% {
            @include box(5000px);
            display: none;
          }
        }
        animation: showSpreader 0.7s ease-in forwards;
      }
    }
  }

  @include respondTo("laptop") {
    padding: 10px 50px 10px 50px;

    .nc-logo {
      @include flexAlign();
      gap: 11px;
      position: relative;
      z-index: 3;
      cursor: pointer;

      .nc-image {
        vertical-align: middle;
        height: 25px;
      }
      .nc-line {
        @include box(0.5px, 0px);
        background-color: #fff;
      }
      .nc-content {
        @include fontProperties(16px, 400, #fff);
        line-height: 70px;
        height: 70px;
        width: 0px;
        position: relative;
        max-width: 0;
        overflow: hidden;

        span {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          @include setTextInOneLine();
        }
      }

      &:hover {
        .nc-content {
          width: 116px;
          max-width: 200px;
        }
        .nc-line {
          @include box(0.5px, 40px);
        }
      }
    }
    .nc-ham {
      @include box(30px);
      @include setToCenter();
      flex-direction: column;
      gap: 6px;
      cursor: pointer;
      @include preventSelection();
      position: relative;
      z-index: 1;

      .spot-spreader {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #000;
        z-index: 0;
        border-radius: 50%;
        width: 0;
        height: 0;
        transition: all 0.7s ease-in-out;
      }

      $barWidth: 26px;
      $hoverRotationAngle: -20deg;

      .nch-bar {
        flex-shrink: 0;
        @include box($barWidth, 3px);
        border-radius: 2px;
        background-color: #ddd;
        transition: all 150ms ease-in-out;
        position: relative;
        z-index: 3;
      }

      &:hover {
        .bar1 {
          transform-origin: center;
          transform: rotate($hoverRotationAngle) translateY(-3px);
          width: 30px;
        }
        .bar2 {
          transform-origin: center;
          transform: rotate($hoverRotationAngle);
          width: 20px;
        }

        .bar3 {
          transform-origin: center;
          transform: rotate($hoverRotationAngle) translateY(3px);
          width: 30px;
        }
      }

      &.active {
        .bar1 {
          transform-origin: left;
          transform: rotate(45deg);
          width: $barWidth;
        }
        .bar2 {
          transform-origin: center;
          scale: 0;
        }

        .bar3 {
          transform-origin: left;
          transform: rotate(-45deg);
          width: $barWidth;
        }

        .spot-spreader {
          @keyframes showSpreader {
            0% {
              @include box(0);
            }
            90% {
              @include box(5000px);
            }
            100% {
              @include box(5000px);
              display: none;
            }
          }
          animation: showSpreader 0.7s ease-in forwards;
        }
      }
    }
  }
}

.navbar-page {
  @keyframes showPage {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  position: fixed;
  height: 100vh;
  inset: 0;
  z-index: 13;
  background-color: #000;
  opacity: 0;
  animation: showPage 150ms 0.4s ease-in forwards;

  .np-sections {
    padding: 0px 20px;
    height: calc(100vh - 90px);
    overflow-y: auto;
    position: relative;
    z-index: 14;
    @include verticalFlex();
    align-items: center;
    gap: 20px;
    margin-top: 90px;
    padding-bottom: 20px;
    cursor: pointer;

    .nps-menu {
      @include fontProperties(100px, 600, transparent);
      -webkit-text-stroke: 1px #fff;
      position: relative;
      transition: all 0.3s ease-out;
      text-decoration: none;

      .npsm-arrow {
        position: absolute;
        left: 0;
        top: 100%;
        width: 0;
        height: 1px;
        transition: all 0.3s ease-out;
        background-color: #fff;
      }

      &:hover {
        color: #fff;
        // text-shadow: 0px 0px 12px #fff;

        .npsm-arrow {
          width: 100%;
        }
      }
    }
  }

  @include mobileView() {
    
    .np-sections {
      @include setToCenter();
    flex-direction: column;
    gap: 30px;

      .nps-menu {
        @include fontProperties(45px, 600, transparent);
        -webkit-text-stroke: 1px #fff;

        .npsm-arrow {
          width: 100%;
        }
      }
    }
  }
}
